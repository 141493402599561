import { Routes, Route } from 'react-router-dom';
import React from 'react';
import Home from './pages/Home';
import History from './pages/History';
import OurBrands from './pages/OurBrands';
import BrandProduct from './pages/BrandProduct';
import ProductDetails from './pages/ProductDetails';
import OurProducts from './pages/OurProducts';
import StoreLocator from './pages/StoreLocator';
import NewsEvent from './pages/NewsEvent';
import NewsEventDetails from './pages/NewsEventDetails';
import Careers from './pages/Careers';
import BookAppointment from './pages/BookAppointment';
import ProductData from './pages/ProductData';
import NewPage from './pages/NewPage';
import Product from './pages/Product';
import Error from "./errorpage/Error"

const AppRoutes = ({ menu, contact }) => {
    return (
        <Routes>
            <Route path="/" element={<Home menu={menu} />} />
            {menu.map((item, index) => {
                return (
                    <React.Fragment key={index}>
                        {index === 0 && <Route path={item.menuUrl} element={<History />} />}
                        {index === 1 && (
                            <>
                                <Route path={item.menuUrl} element={<OurBrands />} />
                                {item.subMenus?.map((brand, id) => {
                                    return (
                                        <React.Fragment key={id}>
                                            {<Route path={`${item.menuUrl}/${brand.menuUrl}`} element={<BrandProduct menu={menu} contact={contact} />} />}
                                            <Route path={`${item.menuUrl}/${brand.menuUrl}/:sku`}
                                                element={<ProductDetails contact={contact} />} />
                                        </React.Fragment>)
                                })}
                            </>
                        )}
                        {index === 2 && (
                            <>
                                <Route path={item.menuUrl} element={<OurProducts contact={contact} menu={menu} />} />
                                {item.subMenus?.map((category, id) => {
                                    return (
                                        <React.Fragment key={id}>
                                            <Route path={`${item.menuUrl}/${category.menuUrl}`} element={<Product menu={menu} contact={contact} />} />
                                            <Route path={`${item.menuUrl}/${category.menuUrl}/:sku`}
                                                element={<ProductDetails contact={contact} />} />
                                        </React.Fragment>)
                                })}
                            </>
                        )}
                        {index === 3 && (
                            <>
                                <Route path={item.menuUrl} element={<ProductData contact={contact} />} />
                                <Route path={`${item.menuUrl}/:slug`} element={<ProductDetails contact={contact} />} />
                            </>
                        )}
                        {index === 4 && <Route path={item.menuUrl} element={<StoreLocator />} />}
                        {index === 5 && (
                            <>
                                <Route path={`/${item.menuUrl}`} element={<NewsEvent menu={menu} />} />
                                <Route path={`/${item.menuUrl}/:slug`} element={<NewsEventDetails menu={menu} />} />
                            </>
                        )}
                        {/* {index === 6 && <Route path={item.menuUrl} element={<Careers />} />} */}
                         <Route path="/careers" element={<Careers />} />
                        <Route path="/:new-page" element={<NewPage />} />
                        <Route path="/randomproduct/:sku" element={<ProductDetails contact={contact} />} />
                        <Route path="/book-appointment" element={<BookAppointment />} />
                        <Route path="*" element={<Error />} />
                    </React.Fragment>
                )
            })}

        </Routes>
    );
};

export default AppRoutes;
