import React, { useCallback, useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import axiosInstance from "../axiosInterceptor";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { getMenuPage } from "../services/getMenuPage";
import { Form, InputGroup, Modal } from "react-bootstrap";

const Careers = () => {
  const { register, handleSubmit, reset } = useForm({});
  const [isLoading, setIsLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const location = useLocation();
  const pathname = location.pathname;
  const [menuPageData, setMenuPageData] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [careerData, setCareerData] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`/api/careerpost`);
        setCareerData(response?.data?.document);
      } catch (error) {
        toast.error(error.response.data.error);
      }
    };
    fetchData();
  }, []);

  const fetchMenuPage = useCallback(async () => {
    if (pathname) {
      const response = await getMenuPage(pathname);
      setMenuPageData(response.document);
    }
  }, [pathname]);

  useEffect(() => {
    fetchMenuPage();
  }, [fetchMenuPage]);

  const handlePhoneChange = (value, e) => {
    const formattedPhone = value.startsWith("+") ? value : `+${value}`;
    setPhone(formattedPhone);
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);

  };

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("first_name", data.first_name);
    formData.append("last_name", data.last_name);
    formData.append("email", data.email);
    formData.append("contact_no", phone);
    formData.append("post", data.post);
    formData.append("experiance", data.experiance);

    if (selectedFile) {
      formData.append("apply_cv", selectedFile);
    }

    try {
      setIsLoading(true);
      const response = await axiosInstance.post(`api/career`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        toast.success(response.data.message);
        setShow(true)
        reset();
        setSelectedFile(null);
        setPhone("");

      }
    } catch (error) {
      if (error.response?.data?.error === 'File too large') {
        toast.error("File size exceeds the limit of 5 MB!")
      } else {
        toast.error(error.response?.data?.error || "An error occurred");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {menuPageData?.name && (
        <Helmet>
          <title>{`K M Choksi - ${menuPageData?.name}`}</title>
        </Helmet>
      )}
      <main id="content">
        <section className="pb-8 pt-lg-7 shadow">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <div className="inner-banner-text text-center">
                  <h1 className="">Careers</h1>
                  <p>Exploring Professional Pathways</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pb-2 mb-2 mt-4 pt-4 ">
          <div className="container">
            <div className=" text-center">
              <h3 className="">Current Openings</h3>
            </div>
            <div className="row">
              <div className="col-md-10 mx-auto">
                <div className="d-flex flex-wrap">
                  {careerData.map((job, index) => (
                    job.no_of_post !== 0 && <div key={index} className="tag mx-2 mb-2 text-capitalize">
                      {`${job.name} (${job.no_of_post})`}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pb-12 pt-lg-8">
          <div className="container">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <h2 className="fs-24 mb-2 font-weight-bold">
                  We would love for you to join us.
                </h2>
                <p className="mb-7">
                  If you're passionate about your work and interested in joining
                  our team, reach out to us.
                </p>
                <form onSubmit={handleSubmit(onSubmit)} >
                  <div className="row ">
                    <div className="col-sm-6 mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First Name*"
                        {...register("first_name")}
                        required
                      />
                    </div>
                    <div className="col-sm-6 mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name*"
                        {...register("last_name")}
                        required
                      />
                    </div>
                    <div className="col-sm-6 mb-3">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Your Email*"
                        {...register("email")}
                        required
                      />
                    </div>
                    <div className="col-sm-6 mb-3">
                      <PhoneInput
                        country={"in"}
                        value={phone}
                        onChange={handlePhoneChange}
                        inputClass="form-control"
                      />
                    </div>
                    <div className="col-sm-6 mb-3">
                      <select
                        className="dropdown-box text-capitalize"
                        {...register("post", { required: true })}
                      >
                        <option value="">--- Apply For ---</option>
                        {careerData.map((job, index) => (
                          job.no_of_post !== 0 && <option value={job.name} key={index}>
                            {job.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-sm-6 mb-3">
                      <InputGroup>
                        <Form.Control
                          placeholder="Add Your CV"
                          value={selectedFile ? selectedFile.name : ""}
                          readOnly
                        />
                        <label
                          className="btn btn-primary h-50px text-center"
                          htmlFor="upload-cv"
                        >
                          Browse
                        </label>
                        <Form.Control
                          type="file"
                          id="upload-cv"
                          accept=".pdf"
                          onChange={handleFileChange}
                          style={{ display: "none" }}
                        />
                      </InputGroup>
                    </div>
                    <div className="col-sm-6 mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your Experience"
                        {...register("experiance")}
                        required
                      />
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-primary text-uppercase letter-spacing-05"
                      disabled={isLoading}
                    >
                      {isLoading ? "Submitting..." : "Apply Now"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Modal show={show} onHide={handleClose} className="custom-modal">
        <Modal.Header className="close border-0">
          <Modal.Title></Modal.Title>
          <span onClick={handleClose}>
            X
          </span>
        </Modal.Header>
        <Modal.Body className="text-center ">
          <img src="../images/check.png" width="15%" alt="coming soon" className="mb-2" />
          <h4 className="popup-text">Thank you for your application</h4>
          Thank you for your application. Our HR team will review your profile, and if it matches our criteria, we will reach out to you.
        </Modal.Body>
        <Modal.Footer className="border-0">
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Careers;
