import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { ImageLink } from "../constants";

const Footer = ({ menu, logo, contact, socialData }) => {
  const menuItems = useMemo(() => {
    return menu?.map((menuItem, i) => (
      menuItem.showInFooter === true && menuItem.isActive === true && (<li className="py-1" key={i}>
        <Link
          to={`/${menuItem.menuUrl}`}
          className="text-gray hover-white font-weight-500"
        >
          {menuItem.name}
        </Link>
      </li>)
    ));
  }, [menu]);

  const contactInfo = useMemo(() => {
    return contact?.map((contactItem, index) => (
      <ul className="list-unstyled mb-0" key={index}>
        <li className="py-1">
          <Link
            target="_blank"
            to={`https://wa.me/${contactItem.whatsup_no
              }?text=${encodeURIComponent(contactItem.company_name)}`}
            className="text-gray hover-white font-weight-500"
          >
            <i className="fab fa-whatsapp mr-1" /> +91 {contactItem.whatsup_no}
          </Link>
        </li>
        <li className="py-1">
          <Link
            to={`tel:${contactItem.office_contact_no}`}
            className="text-gray hover-white font-weight-500"
          >
            <i className="fal fa-phone-alt mr-1" />
            +91 {contactItem.office_contact_no}
          </Link>
        </li>
        <li className="py-1">
          <Link
            to={`mailto:${contactItem.email}`}
            className="text-gray hover-white font-weight-500"
          >
            <i className="fal fa-envelope mr-1" />
            {contactItem.email}
          </Link>
        </li>
      </ul>
    ));
  }, [contact]);

  return (
    <>
      <footer className="py-8 bg-color-6">
        <div className="container container-xxl">
          <div className="row">
            <div className="col-md-6 col-lg-4 mb-6 mb-lg-0 text-center">
              <h3 className="fs-16 mb-3 text-uppercase letter-spacing-05">
                Overview
              </h3>
              <ul className="list-unstyled mb-0">{menuItems}</ul>
            </div>

            <div className="col-md-6 col-lg-4 mb-6 mb-lg-0 text-center f-border-left f-border-right">
              <div className="footer-logo">
                {logo &&
                  logo?.map((data) =>
                    data.showInFooter === true ? (
                      <img
                        key={data._id}
                        src={ImageLink(data.footer_logo_image)}
                        alt="Logo"
                      />
                    ) : null
                  )}
              </div>
              <h3 className="fs-16 mb-3 mt-5 text-uppercase letter-spacing-05">
                Follow Us
              </h3>
              <ul className="followus-icon mb-0">
                {socialData?.map((social, i) => {
                  return (
                    social.isActive === true && (
                      <li key={i}>
                        <Link target="_blank" to={social.url}>
                          <i className={social.icon}></i>
                        </Link>
                      </li>
                    )
                  );
                })}
              </ul>
            </div>
            <div className="col-md-6 col-lg-4 mb-6 mb-lg-0 text-center">
              <h3 className="fs-16 mb-3 text-uppercase letter-spacing-05">
                Contact Us
              </h3>
              {contactInfo}
            </div>
          </div>
          <div className="mt-2 mt-md-7 row align-items-center f-border-top text-center">
            <p className="mx-auto pt-4 mb-4 mb-md-0 text-gray fs-14 font-weight-500">
              © {new Date().getFullYear()} K M Choksi jewellers
              <span className="d-md-inline"> | </span>
              <br className="d-md-none" />
              Website developed by:
              <Link
                target="_blank"
                className="text-gray hover-white mx-1"
                to="https://www.setblue.com/"
                title="+91 98985 98984"
              >
                Setblue
              </Link>
            </p>
          </div>

        </div>
      </footer>
      <div className="">
        <Link
          id="whatsapp-app"
          className="bookappointment whatsapp-link"
          to={`https://wa.me/91${contact?.[0]?.whatsup_no}`}
          target="_blank"
        >
          <i className="fab fa-whatsapp mr-2 fs-20 whatsapp-icon" />
          +91 {contact?.[0]?.whatsup_no}
        </Link>
        <Link
          id="bookApp"
          className="bookappointment book-button"
          to="/book-appointment"
        >
          <i className="fa fa-calendar-check mr-2 fs-20" />
          Book Appointment
        </Link>
      </div>
    </>
  );
};

export default Footer;
