import React, { useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useForm } from 'react-hook-form';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { toast } from 'react-toastify';
import axiosInstance from '../axiosInterceptor';

const SITE_KEY = '6LcwIW0oAAAAALpRr4-ztJ9qEBkfGc0tU7zdG4EX'

const Inquiry = ({ productName, productSku }) => {
    const { register, handleSubmit, reset, formState: { errors }, } = useForm({});
    const [isLoading, setIsLoading] = useState(false);
    const [phone, setPhone] = useState('');
    const [verfied, setVerifed] = useState(false);
    const [recaptchavalue, setrecaptchavalue] = useState('');
    const captcharef = useRef();
 
    function onChange(value) {
        setrecaptchavalue(value);
        setVerifed(true);
    }
    const handlePhoneChange = (value, country, e, formattedValue) => {
        const formattedPhone = value.startsWith('+') ? value : `+${value}`;

        setPhone(formattedPhone);

    };

    const onSubmit = async (data) => {
        try {
            setIsLoading(true);
            const response = await axiosInstance.post(`api/productinquary`, {
                name: data.name,
                email: data.email,
                mobile_no: phone,
                message: data.message,
                productName: productName,
                productCode: productSku,
                recaptcha: recaptchavalue,
                url: window.location.href
            });
            if (response.data.status === 1) {
                toast.success(response.data.message);
                reset();
            }
        } catch (error) {
            toast.error(error.response?.data.originalError);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit((data) => onSubmit(data))} className='mr-4'>
                <div className="row mb-3 mt-3">
                <div className="col-sm-12">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Your Name*"
                        name="name"
                        {...register("name")}
                        required
                        onInput={(e) => {
                            e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, '');
                        }}
                        title="Only alphabetic characters (A-Z, a-z) and spaces are allowed"
                    />
                </div>
                </div>
                <div className="row mb-3">
                    <div className="col-sm-6 mb-3">
                        <input type="email" className='form-control' placeholder="Your Email*" required id="email"
                            name="email" {...register("email", { required: true, })} />
                        {errors.email && (
                            <p className="error text-right text-danger">
                                Please Enter a Email
                            </p>
                        )}
                    </div>
                    <div className="col-sm-6 mb-3">
                        <PhoneInput
                            country={"in"}
                            value={phone}
                            onChange={handlePhoneChange}
                            inputClass="form-control"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group mb-3">
                            <textarea type="text" className='form-control' rows={6} name="message" required
                                {...register("message", { required: "Please Enter a Message", })} />
                        </div>
                    </div>
                </div>

                <div className="row d-flex justify-content-between align-items-center">
                    <div className="col-sm-6 col-md-6 col-lg-6 book book-inquiry">
                        <ReCAPTCHA                      
                            sitekey={SITE_KEY}
                            onChange={onChange}
                            ref={captcharef}
                        />
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6 px-7 book mt-5">
                        <button
                            type="submit"
                            disabled={!verfied}
                            className="btn btn-primary mb-5 text-uppercase letter-spacing-05 w-100 cursor-pointer send-inquiry"
                        >
                            {isLoading ? "Sending..." : "Send Now"}
                        </button>
                    </div>
                </div>
            </form>
        </>
    )
}

export default Inquiry;