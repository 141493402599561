import React, { useCallback, useEffect, useState } from "react";
import { getBranch } from "../services/getBranch";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import axiosInstance from "../axiosInterceptor";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { getMenuPage } from "../services/getMenuPage";
import { ImageLink } from "../constants";
import Loader from "../layout/Loader";

const StoreLocator = () => {
  const [branch, setBranch] = useState([]);
  const { register, handleSubmit, reset } = useForm({});
  const [isLoading, setIsLoading] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [name, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");
  const location = useLocation();
  const pathname = location.pathname;
  const [menuPageData, setMenuPageData] = useState(null);

  const fetchMenuPage = useCallback(async () => {
    if (pathname) {
      const response = await getMenuPage(pathname);
      setMenuPageData(response.document);
      setIsLoading(false);
    }
  }, [pathname]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getBranch();
        setBranch(res.document);
        await fetchMenuPage();
      } catch (error) {
        toast.error(error.response.data.error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [fetchMenuPage]);

  const handlePhoneChange = (value, e) => {
    const formattedPhone = value.startsWith("+") ? value : `+${value}`;
    setPhone(formattedPhone);
  };

  const onSubmit = async (data) => {
    localStorage.setItem("name", data.name);
    localStorage.setItem("email", data.email);
    localStorage.setItem("phone", phone);
    localStorage.setItem("message", data.message);

    try {
      const response = await axiosInstance.post(`api/contactinquary`, {
        name: data.name,
        email: data.email,
        mobile_no: phone,
        message: data.message,
      });
      if (response.status === 200) {
        toast.success(response.data.message);
        reset();
      }
      setIsLoading(false);
    } catch (error) {
      toast.error(error.response?.data?.originalError || "An error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const savedUsername = localStorage.getItem("name");
    const savedUseremail = localStorage.getItem("email");
    const savedUserno = localStorage.getItem("phone");
    const savedUsermsg = localStorage.getItem("message");
    const savedIsChecked = localStorage.getItem("isChecked");

    if (savedUsername) {
      setUsername(savedUsername);
    }
    if (savedUseremail) {
      setEmail(savedUseremail);
    }
    if (savedUserno) {
      setPhone(savedUserno);
    }
    if (savedUsermsg) {
      setMessage(savedUsermsg);
    }
    if (savedIsChecked) {
      setIsChecked(savedIsChecked === "true");
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("isChecked", isChecked.toString());
  }, [isChecked]);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  if (isLoading) {
    return (
      <div className="pre-loader">
        <Loader />
      </div>
    );
  }
  return (
    <>
      {menuPageData?.CMS?.title && (
        <Helmet>
          <title>{`K M Choksi - ${menuPageData?.CMS?.title}`}</title>
        </Helmet>
      )}
      <main id="content">
        {menuPageData?.CMS?.background_image ? <section
          className="inner-banner"
          style={{
            backgroundImage: `url(${ImageLink(
              menuPageData?.CMS?.background_image
            )})`,
          }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3">
                <div className="inner-banner-text">
                  <h1>{menuPageData?.CMS?.title}</h1>
                  <p>{menuPageData?.CMS?.subTitle}</p>
                </div>
              </div>
              <div className="col-lg-9">
                <img
                  className="w-100 inner-banner-img"
                  src={ImageLink(menuPageData?.CMS?.main_banner_image)}
                  alt=""
                />
              </div>
            </div>
          </div>
        </section> :
          <section className="inner-banner" style={{
            backgroundImage: `url(../images/4.jpg)`,
            height: '308px',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }} />
        }

        <section className="pt-lg-10 pt-5 pb-10">
          <div className="container ">
            {branch &&
              branch?.map((data, id) => {
                return data.isActive === true ? (
                  <div className="row mt-4 mb-6" key={id}>
                    <div className="col-lg-5 mb-6 d-flex align-items-center">
                      <div className="text-left">
                        <h4 className="font-weight-bold mb-2"> {data.name}</h4>
                        <p className="mb-3"> {data.address}</p>
                        <p className=" mb-0">Contact: {data.mobile_no}</p>
                        <p className=" mb-0">
                          Email:
                          <Link to="mailto:Info@kmchoksi.com" className="mx-1">
                            {data.email}
                          </Link>
                        </p>
                        <>
                          <div className="google-map-area w-100 mt-2">
                            <iframe
                              className="contact-map"
                              src={data.googleMap}
                              width="100%"
                              height={300}
                              style={{ border: 0 }}
                              allowFullScreen=""

                              referrerPolicy="no-referrer-when-downgrade"
                              title="Google Map"
                            />
                          </div>
                        </>
                      </div>
                    </div>
                    <div className="col-lg-7 mb-6 mb-md-0">
                      <img
                        src={ImageLink(data.branch_image)}
                        alt="branch-img"
                      />
                    </div>
                  </div>
                ) : (
                  ""
                );
              })}
          </div>
        </section>
        <section className="pb-12">
          <div className="container">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <h2 className="fs-24 mb-2 font-weight-bold">
                  We would love to hear from you.
                </h2>
                <p className="mb-7">
                  If you’ve got great products your making or looking to work
                  with us then drop us a line.
                </p>
                <form onSubmit={handleSubmit((data) => onSubmit(data))}>
                  <div className="row">
                    <div className="col-sm-12 mb-2 mb-md-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your Name*"
                        name="name"
                        {...register("name")}
                        defaultValue={name}
                        required
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, '');
                        }}
                        title="Only alphabetic characters (A-Z, a-z) are allowed"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 mb-6">
                      <input
                        type="email"
                        className="form-control mobile-view"
                        placeholder="Your Email*"
                        name="email"
                        id="email"
                        {...register("email")}
                        defaultValue={email}
                        required
                      />
                    </div>
                    <div className="col-sm-6 mb-6">
                      <PhoneInput
                        country={"in"}
                        value={phone}
                        onChange={handlePhoneChange}
                        inputClass="form-control"
                      />
                    </div>
                  </div>
                  <div className="form-group mb-4">
                    <textarea
                      type="text"
                      className="form-control"
                      rows={6}
                      name="message"
                      {...register("message")}
                      defaultValue={message}
                      required
                    />
                  </div>
                  <div className="custom-control custom-checkbox mb-6">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customCheck1"
                      onChange={handleCheckboxChange}
                      checked={isChecked}
                    />
                    <label
                      className="custom-control-label fs-15"
                      htmlFor="customCheck1"
                    >
                      Save my name, email, and website in this browser for the
                      next time I comment.
                    </label>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-primary text-uppercase letter-spacing-05"
                      disabled={isLoading}
                    >
                      {isLoading ? "Submitting..." : "Submit Now"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default StoreLocator;
