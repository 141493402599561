import React, { useEffect, useState } from 'react';
import Inquiry from './Inquiry';
import { getProductById } from '../services/getProductById';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ImageLink } from '../constants';
import { Helmet } from 'react-helmet';
import Counter from "yet-another-react-lightbox/plugins/counter";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
import Loader from '../layout/Loader';
import { toast } from 'react-toastify';

const ProductView = ({ contact }) => {
    const location = useLocation();
    const navigator = useNavigate();
    const productSku = location.state?.slug || '';
    const pathname3 = location.pathname.split("/")[3];
    const pathname2 = location.pathname.split("/")[2];
    const [getProduct, setgetProduct] = useState([]);
    const [shouldHide, setShouldHide] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const productName = getProduct?.[0]?.name;
    const colors = getProduct?.[0]?.color || [];
    const goldpuritys = getProduct?.[0]?.gold_purity || [];
    const grossWeight = getProduct?.[0]?.gross_weight || [];
    const metal = getProduct?.[0]?.metal || [];
    const whatsappMessage = ` Hello, \n I want to know more about \n ${productName}`;
    const whatsappLink = `https://wa.me/91${contact?.[0]?.whatsup_no}?text=${encodeURIComponent(whatsappMessage)}`;
    const [showForm, setShowForm] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [currentProductImages, setCurrentProductImages] = useState([]);

    const handleToggleForm = () => {
        setShowForm(prevShowForm => !prevShowForm);
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;

            if (scrollY > 500) {
                setShouldHide(true);
            } else {
                setShouldHide(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [getProduct]);

    useEffect(() => {
        const fetchProductData = async () => {
            setIsLoading(true);
            try {
                const productId = pathname3 || pathname2 || productSku;
                const res = await getProductById(productId);
                setgetProduct(res.document);
                if (res.document === "product not available") {
                    navigator("/")
                }
            } catch (error) {
                toast.error('Error fetching product data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchProductData();
    }, [productSku, pathname3, pathname2, navigator]);

    const handleThumbClick = (productId, index) => {
        setCurrentImageIndex(prevState => ({
            ...prevState,
            [productId]: index
        }));
    };

    const handleMainImageClick = (productId, index) => {
        const product = getProduct.find(item => item?._id === productId);
        if (product) {
            setCurrentProductImages(product.product_image.map(img => ImageLink(img)));
            setIsOpen(true);
        }
    };

    if (isLoading) {
        return (
            <div className="pre-loader">
                <Loader />
            </div>
        );
    }
    console.log("getProduct", getProduct);

    return (
        <>
            <Helmet>
                <title>{`K M choksi - ${getProduct[0]?.metaTitle}`}</title>
                <meta name="description" content={getProduct[0]?.metaDescription || "K M choksi"} />
                <meta name="Keyword" content={getProduct[0]?.metaKeyword || "K M choksi"} />
            </Helmet>
            {contact && <main id="content">
                <section className="pt-10 pb-lg-15 pb-11">
                    <div className="container">
                        <div className="row no-gutters">
                            <div className="col-md-7 mb-8 mb-md-0 position-relative pr-md-6 pr-lg-10">
                                {Array.isArray(getProduct) && getProduct.map((item, index) => {
                                    const activeIndex = currentImageIndex[item?._id] || 0;
                                    return (
                                        <div className="d-flex" key={item?._id}>
                                            <div>
                                                <div
                                                    id="list-dots"
                                                    className={`my-div ${shouldHide ? 'd-none' : 'list-group product-image-dots dots-thumbs mr-2 rounded-0'}`}
                                                >
                                                    {item?.thumbproduct_image?.map((thumb, thumbIndex) => (
                                                        <Link
                                                            key={thumbIndex}
                                                            className={`list-group-item list-group-item-action p-0 d-flex mb-2 w-80px ${thumbIndex === activeIndex ? 'active' : ''}`}
                                                            to={`#gallery-${thumbIndex + 1}`}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                handleThumbClick(item._id, thumbIndex);
                                                            }}
                                                        >
                                                            <img src={ImageLink(thumb)} alt={`Thumb ${thumbIndex + 1}`} />
                                                        </Link>
                                                    ))}
                                                </div>
                                                <div className="scrollspy-images ml-md-12">
                                                    <Link
                                                        to="#"
                                                        target='_blank'
                                                        className="d-block mb-2"
                                                        id={`gallery-${activeIndex + 1}`}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            handleMainImageClick(item?._id, activeIndex);
                                                        }}
                                                    >
                                                        <img src={ImageLink(item?.product_image[activeIndex])}
                                                            alt="Product" />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="col-md-5 primary-summary summary-sticky" id="summary-sticky">
                                <div className="primary-summary-inner" >
                                    <h2 className="fs-28 mb-4">{getProduct?.[0]?.name}</h2>
                                    <ul className="list-unstyled border-top pt-2">
                                        {getProduct?.[0]?.brand?.[0]?.name ? <li className="row mb-2">
                                            <span className="d-block col-lg-3 col-6 text-primary font-weight-500 fs-15">
                                                Brand name:
                                            </span>
                                            <span className="d-block col-lg-9 col-6 fs-15">
                                                {getProduct?.[0]?.brand?.map((item, i) => <span className="mr-1" key={i}>{item.name} {i !== getProduct?.[0]?.brand?.length - 1 && ' | '}</span>)}
                                            </span>
                                        </li> : ""}
                                        <li className="row mb-2">
                                            <span className="d-block col-lg-3 col-6 text-primary font-weight-500 fs-15">
                                                Design code:
                                            </span>
                                            <span className="d-block col-lg-9 col-6 fs-15">
                                                {getProduct?.[0]?.sku}
                                            </span>
                                        </li>
                                        <li className="row mb-2">
                                            <span className="d-block col-lg-3 col-6 text-primary font-weight-500 fs-15">
                                                Metal:
                                            </span>
                                            <span className="d-block col-lg-9 col-6 fs-15">
                                                {metal?.map((item, index) => (
                                                    <React.Fragment key={index}>
                                                        {item.name}
                                                        {index !== metal?.length - 1 && ' | '}
                                                    </React.Fragment>
                                                ))}
                                            </span>
                                        </li>
                                        <li className="row mb-2 color-widget">
                                            <span className="d-block col-lg-3 col-6 text-primary font-weight-500 fs-15">
                                                Color:
                                            </span>
                                            <span className="d-block col-lg-9 col-6 fs-15 d-flex">
                                                {colors?.map((color, index) => (
                                                    <React.Fragment key={index}>
                                                        {color.name}
                                                        {index !== colors?.length - 1 && ' | '}
                                                    </React.Fragment>
                                                ))}
                                            </span>
                                        </li>
                                        <li className="row mb-2">
                                            <span className="d-block col-lg-3 col-6 text-primary font-weight-500 fs-15">
                                                Gold Purity:
                                            </span>
                                            <span className="d-block col-lg-9 col-6 fs-15">
                                                {goldpuritys?.map((item, index) => (
                                                    <React.Fragment key={index}>
                                                        {item.name}
                                                        {index !== goldpuritys?.length - 1 && ' | '}
                                                    </React.Fragment>
                                                ))}
                                            </span>
                                        </li>
                                        <li className="row mb-2">
                                            <span className="d-block col-lg-3 col-6 text-primary font-weight-500 fs-14">
                                                Gross Weight:
                                            </span>
                                            <span className="d-block col-lg-9 col-6 fs-15">
                                                {grossWeight?.map((item, index) => (
                                                    <React.Fragment key={index}>
                                                        {item}
                                                        {index !== grossWeight?.length - 1 && ' | '}
                                                    </React.Fragment>
                                                ))}
                                            </span>
                                        </li>
                                    </ul>

                                    <div className="d-sm-flex items-center d-block cursor-pointer ">
                                        <div className="d-flex align-items-center flex-wrap mt-5 mr-6">
                                            <Link to={whatsappLink}
                                                target="_blank" className="btn-whatsapp text-capitalize font-weight-bold letter-spacing-05 fs-14">
                                                <i className="fab fa-whatsapp" />
                                                <span className="ml-1">Inquiry</span>
                                            </Link>
                                        </div>
                                        <div className="d-flex align-items-center flex-wrap mt-5 mr-6">
                                            <span onClick={handleToggleForm}
                                                className={`btn-envelope text-capitalize font-weight-bold letter-spacing-05 fs-14`}>
                                                <i className="fa fa-envelope" />
                                                <span className="ml-1">Inquiry</span>
                                            </span>
                                        </div>
                                    </div>

                                    {showForm && (
                                        <div className="mt-4">
                                            <Inquiry productName={productName} productSku={productSku} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Lightbox
                    open={isOpen}
                    close={() => setIsOpen(false)}
                    slides={currentProductImages.map((img) => ({ src: img }))}
                    plugins={[Counter]}
                    render={{
                        buttonPrev: currentProductImages.length <= 1 ? () => null : undefined,
                        buttonNext: currentProductImages.length <= 1 ? () => null : undefined,
                        counter: ({ currentIndex, total }) => (
                            <div className="lightbox-counter">
                                {currentIndex + 1} / {total}
                            </div>
                        ),
                    }}
                />
            </main>}
        </>
    );
};

export default ProductView;
