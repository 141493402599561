import React, { useEffect } from "react";

const Section9 = () => {
  useEffect(() => {
    const ashapuriInstaForm = document.getElementById("snapppt-widget");

    if (!ashapuriInstaForm?.hasChildNodes()) {
      const script = document.createElement("script");
      script.src = 'https://app.addsauce.com/widgets/widget_loader/d710d4c7-26ba-1b88-221f-231ca8d11d7f/sauce_homepage.js';
      script.async = true;
      ashapuriInstaForm?.appendChild(script);
    }
  });

  return (
    <>
      <section className="instagram-bg">
        <div className="container container-xxl">
          <form id="snapppt-widget"></form>
        </div>
      </section>
    </>
  );
};

export default Section9;
