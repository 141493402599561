import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import { getTime } from '../services/getTime';
import { getCity } from '../services/getCity';
import axiosInstance from '../axiosInterceptor';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';

const SITE_KEY = '6LcwIW0oAAAAALpRr4-ztJ9qEBkfGc0tU7zdG4EX'

const BookAppointment = () => {
    const { register, handleSubmit, watch, reset } = useForm({});
    const selectCity = watch("city")
    const selectStore = watch("store")
    const [selectedDate, setSelectedDate] = useState('');

    const [verfied, setVerifed] = useState(false);
    const [recaptchavalue, setrecaptchavalue] = useState('');
    const [city, setCity] = useState([]);
    const [time, setTime] = useState([]);
    const captcharef = useRef();

    function onChange(value) {
        setrecaptchavalue(value);
        setVerifed(true);
    }
    const handleDateChange = (e) => {
        const date = e.target.value;
        setSelectedDate(date);
    };

    useEffect(() => {
        if (selectedDate) {
            (async function () {
                const res = await getTime(selectedDate);
                setTime(res.document);
            })();
        }
        (async function () {
            const res = await getCity();
            setCity(res.document);
        })();
    }, [selectedDate]);

    const filteredCities = city?.filter((city) => (city.city === selectCity ? city.name : ""));
    const [phone, setPhone] = useState('');

    const handlePhoneChange = (value) => {
        const formattedPhone = value.startsWith('+') ? value : `+${value}`;

        setPhone(formattedPhone);
    };

    const onSubmit = async (data) => {
        try {
            const formData = {
                ...data,
                date: selectedDate,
                recaptcha: recaptchavalue,
                mobile_no: phone,
            };

            const response = await axiosInstance.post(`api/appointment`, formData);

            if (response.status === 200) {
                toast.success(response.data.message);
                reset();
            }
        } catch (error) {
            toast.error(error.response?.data?.originalError);
        }
    };

    return (
        <>
            <main id="content">
                <section className="inner-banner m-0 p-0">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-6">
                                <img className="w-100" src="images/book-appointment.jpg" alt="" />
                            </div>
                            <div className="col-lg-6 d-flex mt-4 justify-content-center pl-lg-10 pr-lg-10">
                                <form onSubmit={handleSubmit((data) => onSubmit(data))}>
                                    <h2 className="fs-30 mb-5 font-weight-bold text-center">
                                        Book An Appointment
                                    </h2>
                                    <div className="row">
                                        <div className="col-sm-6 mb-4">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Your Name*"
                                                name="name"
                                                {...register("name")}
                                                required
                                                onInput={(e) => {
                                                    e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, '');
                                                }}
                                                title="Only alphabetic characters (A-Z, a-z) are allowed"
                                            />
                                        </div>
                                        <div className="col-sm-6 mb-4">
                                            <PhoneInput
                                                country={"in"}
                                                value={phone}
                                                onChange={handlePhoneChange}
                                                inputClass="form-control"
                                            />
                                        </div>
                                        <div className="col-sm-6 mb-4">
                                            <div className="custom-control custom-radio">
                                                <input
                                                    className="custom-control-input"
                                                    type="radio"
                                                    name="book-form"
                                                    id="store-visit"
                                                    value={1}
                                                    defaultValue="option1"
                                                    {...register("type")}
                                                />
                                                <label
                                                    className="custom-control-label text-primary ml-2"
                                                    htmlFor="store-visit">
                                                    Store Visit
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mb-4">
                                            <div className="custom-control custom-radio">
                                                <input
                                                    className="custom-control-input"
                                                    type="radio"
                                                    name="book-form"
                                                    id="video-call"
                                                    value={2}
                                                    defaultValue="option1"
                                                    {...register("type")}
                                                />
                                                <label
                                                    className="custom-control-label text-primary ml-2"
                                                    htmlFor="video-call">
                                                    Video Call
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6 mb-4">
                                            <select
                                                className='dropdown-box'
                                                name="country"
                                                {...register("city", { required: true })}
                                            >
                                                <option value="">Choose City*</option>
                                                {city?.map((city) => {
                                                    return (
                                                        <option value={city.city}>{city.city}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        <div className="col-sm-6 mb-4">
                                            <select
                                                className='dropdown-box'
                                                {...register("store")}
                                                required
                                            >
                                                <option value="">Choose Store*</option>
                                                {filteredCities?.map((city) => (
                                                    <option key={city._id} value={city.name}>
                                                        {city.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    {selectCity && selectStore &&
                                        <div className="row">
                                            <div className="col-sm-6 mb-4">
                                                <input
                                                    type="date"
                                                    className='form-control'
                                                    placeholder="Pick the Date*"
                                                    onChange={handleDateChange}
                                                    required
                                                    name="date"
                                                    min={new Date().toISOString().split("T")[0]}
                                                />
                                            </div>

                                            {/* <div className="col-sm-6 mb-4">
                                            <select
                                                className='dropdown-box'
                                                {...register("time")}
                                                required
                                            >
                                                <option value="">Choose time*</option>
                                                {time?.map((timeSlot) => {
                                                    const currentTime = new Date();
                                                    const slotTime = new Date(timeSlot.timeslot.time); // assuming timeslot.time is a valid datetime string

                                                    // Disable time slots that are in the past
                                                    const isPast = slotTime > currentTime;
console.log(isPast,slotTime, currentTime,"isPast");

                                                    return (
                                                        <option 
                                                            key={timeSlot._id} 
                                                            value={timeSlot.name} 
                                                            disabled={timeSlot.isBooked === true || isPast}
                                                        >
                                                            {timeSlot.timeslot.time}
                                                            <span className="text-danger">
                                                                {timeSlot.isBooked === true ? " booked" : ""}
                                                                {isPast ? " (past)" : ""}
                                                            </span>
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div> */}

                                            <div className="col-sm-6 mb-4">
                                                <select
                                                    className='dropdown-box'
                                                    {...register("time")}
                                                    required
                                                >
                                                    <option value="">Choose time*</option>
                                                    {time?.map((timeSlot) => {
                                                        const currentTime = new Date();
                                                        const slotTime = new Date(timeSlot.timeslot.time);
                                                        const isPast = slotTime > currentTime;
                                                        return (
                                                            <option
                                                                key={timeSlot._id}
                                                                value={timeSlot.name}
                                                                disabled={timeSlot.isBooked === true || isPast}
                                                            >
                                                                {timeSlot.timeslot.time}
                                                                <span className="text-danger">
                                                                    {timeSlot.isBooked === true ? " booked" : ""}
                                                                    {isPast ? " (past)" : ""}
                                                                </span>
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>

                                            {/* <div className="col-sm-6 mb-4">
                                            <select
                                                className='dropdown-box'
                                                {...register("time")}
                                                required
                                            >
                                                <option value="">Choose time*</option>
                                                {time?.map((time) => {
                                                    return (
                                                        <option key={time._id} value={time.name} disabled={time.isBooked === true}>
                                                            {time.timeslot.time}
                                                            <span className="text-danger">{time.isBooked === true ? "    booked" : ""}</span>
                                                        </option>
                                                    )
                                                })}
                                            </select>
                                        </div> */}
                                        </div>}
                                    <div className="form-group mb-4">
                                        <textarea
                                            type="text"
                                            className='form-control'
                                            rows={4}
                                            name="comment"
                                            {...register("message")}
                                        />
                                    </div>
                                    <div className="row d-flex justify-content-between align-items-center">
                                        <div className="col-sm-6 col-md-6 col-lg-6 book">
                                            <ReCAPTCHA
                                                sitekey={SITE_KEY}
                                                onChange={onChange}
                                                ref={captcharef}
                                            />
                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-6 px-7 book">
                                            <button
                                                type="submit"
                                                disabled={!verfied}
                                                className="btn btn-primary mb-2 mt-3 text-uppercase letter-spacing-05 w-100 cursor-pointer">
                                                submit now
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default BookAppointment